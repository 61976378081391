@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: rgb(237, 242, 248);
  --secondary-color: rgb(49, 59, 172);
  --black-color: rgb(3, 3, 3);
  --lightGray-color: rgb(228, 228, 228);
  --gray-color: rgb(107, 118, 136);
  --brown-color: rgb(70, 54, 74);
  --white-color: rgb(255, 255, 255);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}